<template>
    <div
        class           ="a-modal modal fade"
        tabindex        ="-1"
        role            ="dialog"
        aria-labelledby ="myLargeModalLabel"
        aria-hidden     ="true"
        :class          ="{'show sticky-top-modal': sticky, 'in' : show}"
        :style          ="css_modal"
        :id             ="id"
        @click.self     ="$emit('close')"
    >
        <div class="modal-dialog modal-lg" :class="{'show-message': showSuccessMessage}">
            <div class="modal-content">
                <div class="a-modal-sticky-top" v-if="showModalTopNav" :class="{'sticky-top': sticky}" @mouseenter="loaded = true">
                    <slot name="top-btn"></slot>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ar-modal',
    props:{
        show:{
            default:false
        },
        modal_width: {
            default: '90%'
        },
        id: {
            type: String,
            default: ''
        },
        showModalTopNav: {
            default: true
        },
        showSuccessMessage: {
            default: false
        }
    },
    data() {
        return {
            scrollTop: 0,
            mousePosition: 0,
            sticky: false,
            loaded: false
        }
    },
    computed:{
        css_modal(){
            return {
                '--modal-width' : this.modal_width
            }
        }
    },
    mounted() {
        this.showSticky()
        this.initializeCloseListener()
        this.emitWhenModalClose()
    },
    methods: {
        emitWhenModalClose() {
            let self = this
            if($(`#${self.id}`).length) {
                $(`#${self.id}`).on('hidden.bs.modal', function() {
                    //catch the native bootstrap close event and trigger yours
                    self.$emit('modal-close');
                });
            }
        },
        showSticky() {
            var _stickyTop = document.getElementsByClassName('a-modal-sticky-top')[0]
            var _modal = document.getElementById('listing-modal')

            if (typeof _modal != "undefined" && _modal != null) {
                _modal.addEventListener("scroll", (e) => {
                    e.preventDefault()
                    this.scrollTop = _modal.scrollTop
    
                    if (this.scrollTop > 56) {
                        _stickyTop.style.top = "-56px"
                        _modal.classList.add("show")
                        this.sticky = false
                    } else if (this.scrollTop == 0 && this.loaded) {
                        _stickyTop.style.top = "29px"
                    } else {
                        _stickyTop.style.top = "1px"
                    }

                    this.loaded = false;
                })
            }

            document.addEventListener("mousemove", (e) => {
                this.mousePosition = e.clientY

                if (this.scrollTop < 56) return;

                if (this.mousePosition <= 56) {
                    _stickyTop.style.top = "0"
                    this.sticky = true
                }
            })
        },
        initializeCloseListener() {
            $(() => {
                let vm = this
                $('.modal').on('show.bs.modal', function (e) {
                    vm.loaded = true;
                });
            })
        }
    }
}
</script>

<style>
    .a-modal .modal {
        margin-top: 0;
    }

    .a-modal.modal {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }

    .a-modal.sticky-top-modal {
        -webkit-transform: none;
        transform: none;
    }

    .a-modal .modal-dialog {
        -webkit-transition: all .3s ease-in-out !important;
        transition: all .3s ease-in-out !important;
    }

    .a-modal .modal-dialog.show-message {
        top: 22%;
    }

    .a-modal .modal-lg {
        max-width: 100%;
        /* margin-top: 0; */
        width: var(--modal-width);
    }

    .a-modal .modal-body {
        padding: 0;
    }

    .a-modal .modal-content {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        padding: 100px 35px 35px;
        border-radius: 10px;
        min-height: 0px;
    }

    .a-modal .a-modal-sticky-top {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 10px 10px 0px 0px;
        -webkit-transition: visibilty 0s, opacity 2s ease, top .2s ease;
        transition: visibility 0s, opacity 2s ease, top .2s ease;
        padding: 14px 15px 10px;
        visibility: hidden;
        font-weight: 500;
        position: absolute;
        max-width: 100%;
        display: block;
        margin: 0 auto;
        width: 93%;
        height: 56px;
        right: 15px;
        z-index: 3;
        opacity:0;
        top: 1px;
        left: 0;
    }

    .a-modal .a-modal-sticky-top.sticky-top {
        position: fixed;
        width: 85.4%;
        right: 14px;
    }

    .a-modal .a-modal-sticky-top:before {
        content: "";
        background: #4e4e4e30;
        position: absolute;
        max-width: 100%;
        display: block;
        margin: 0 auto;
        width: 100%;
        height: 1px;
        bottom:0;
        right:-10px;
        left:0;
    }

    .a-modal.show .a-modal-sticky-top {
        visibility: visible;
        opacity: 1;
    }

    .a-modal-seller-info-item {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        padding: 15px 20px 13px 67px;
        word-break: break-all;
        letter-spacing: 0.25px;
        margin-bottom: 10px;
        border-radius: 4px;
        position: relative;
        text-align: left;
        color: #141517;
        font-size: 14px;
        min-height:68px;
        opacity: 1;
    }

    .a-modal-seller-info-item h5 {
        letter-spacing: 0.24px;
        font-weight: 500;
        color: #141517;
        font-size: 14px;
        margin:0;
    }

    .a-modal-seller-info-item h5 span {
        letter-spacing: 0;
        font-weight: 400;
        margin-top: 5px;
        font-size: 12px;
        display: block;
    }

    .a-modal-seller-info-item i, .a-modal-seller-info-item img {
        -webkit-transform:translateY(-50%);
        transform: translateY(-50%);
        position:absolute;
        font-size: 32px;
        left:15px;
        top:47%;
    }

    .a-modal-seller-info-item img {
        width: 24px;
    }

    .a-modal-check-info-item {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        padding: 8px 8px 8px 38px;
        letter-spacing: 0.25px;
        display: inline-block;
        margin-bottom: 10px;
        border-radius: 4px;
        position: relative;
        text-align: left;
        font-size: 14px;
        color: #141517;
        min-height:34px;
        opacity: 1;
    }

    .a-modal-check-info-item i, .a-modal-check-info-item img {
        -webkit-transform:translateY(-50%);
        transform: translateY(-50%);
        position:absolute;
        color: #3E88DA;
        font-size: 12px;
        left:10px;
        top:55%;
    }

    .a-modal-evidence {
        display: inline-block;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        height: 120px;
        width: 280px;
    }

    .a-modal-evidence img {
        -webkit-transform: translate(-50%, -50%) scale(2.12);
        transform: translate(-50%, -50%) scale(2.12);
        background: #f4f4f3;
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
    }

    .a-modal-evidence span {
        background: rgb(62,135,218, 0.8);
        border-radius: 0 40px 40px 0;
        letter-spacing: 1px;
        text-align: center;
        position: absolute;
        display: block;
        color: #fff;
        height: 36px;
        min-width: 158px;
        max-width: 100%;
        padding: 8px;
        z-index: 2;
        bottom: 0;
        left: 0;
    }

    .a-modal-evidence .btn-remove {
        background        : #3F3D3A7D 0% 0% no-repeat padding-box;
        -webkit-transition: opacity .2s ease;
        transition        : opacity .2s ease;
        position          : absolute;
        text-align        : center;
        border-radius     : 4px;
        line-height       : 36px;
        font-size         : 14px;
        max-width         : 100%;
        display           : block;
        color             : #fff;
        height            : 36px;
        width             : 89px;
        right             : 10px;
        top               : 9px;
        z-index           : 2;
    }

    .a-modal-evidence .btn-remove:hover {
        opacity: 0.8;
    }

    .a-modal-seller-info {
        /* width: calc(100% - 398px); */
        /* float: left; */
        position: relative;
        text-align: left;
    }

    .a-modal-seller-info .img-container {
        border-radius: 4px 4px 0px 0px;
        /* background: #000000DE; */
        border: 1px solid #eee;
        position: relative;
        margin-bottom: 20px;
        overflow: hidden;
        height: 242px;
        max-width: 100%;
        /* width: 528px; */
    }

    .a-modal-seller-info .img-container img,
    .a-modal-seller-info .img-container object {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        position: absolute;
        /* width: 100%; */
        height: 100%;
        left: 50%;
        top: 50%;
    }

    .a-modal-seller-info .seller-details {
        position: relative;
        font-size: 14px;
        display: block;
    }

    .a-modal-seller-info .seller-details img, .a-modal-seller-info .seller-details object {
        position: absolute;
        border-radius: 4px;
        height: 40px;
        width: 40px;
        right: 8px;
        top: 8px;
    }

    .a-modal-seller-info .seller-details h5 {
        font-weight: 400 !important;
        margin-bottom: 25px;
        color: #000000DE;
        font-size: 24px;
    }

    .a-modal-seller-info .seller-details h5 span {
        letter-spacing: 0.4px;
        color: #6EB4F9;
        font-size: 12px;
        margin-top: 2px;
        display: block;
    }

    .a-modal-seller-info .seller-details a {
        display: inline-block;
        word-break: break-all;
    }

    .a-modal-status-history-con {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #6EB4F95C;
        border-radius: 5px;
        padding: 16px 0 16px 29px;
        /* width: 500px; */
        width: 100%;
        float: right;
    }

    .a-modal-status-history {
        overflow: auto;
        min-height: 364px;
        max-height: 346px;
        padding-right: 29px;
    }

    .a-modal .listing-loading-state {
        position: relative;
        height: 100vh;
    }

    .a-modal .listing-loading-state img {
        position: absolute;
        width: 125.9%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        left: 49.6%;
        top: -221px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    .a-modal .remarks-con {
        margin-top: 30px;
        border: 2px dashed #172d4f;
        padding: 20px;
        border-radius: 4px;
        background-color: #6eb4f933;
    }

    .a-modal .a-modal-case-details-con {
        height: 439px;
        overflow: hidden;
        overflow-y: auto;
    }

    .a-modal .another-payment-accounts {
        border    : 1px solid #ccc;
        border-radius: 4px;
        text-align: left;
        overflow: hidden;
        padding   : 16px;
        font-size: 14px;
        /* min-height: 259px; */
        min-height: 0;
        width: 100%;
    }

    .a-modal .another-payment-accounts.main-account {
        border    : 2px dashed rgb(41, 171, 162);
        background: rgba(41, 171, 162, 0.2);
    }

    .a-modal .another-payment-accounts img {
        position: relative;
        height: 12px;
        width: 12px;
        right: initial;
        top: -1px;
    }

    .a-modal .another-payment-accounts table tr {
        border: 0;
    }

    .a-modal .another-payment-accounts table tr .header-label {
        text-transform: uppercase;
        color         : #515365;
        font-weight   : 400;
    }

    .a-modal .another-payment-accounts table tr td {
        font-weight: 500;
        color      : #141517;
        font-size  : 12px;
        padding    : 7px;
    }
</style>