<template>
    <a class="card a-nav-link" :href="link" :class="a_class" @click="$emit('click')">
        <img class="card-img-top" :class="img_class" :src="img">
        <div class="card-body">
            <h5 class="card-title"><slot></slot></h5>
        </div>
    </a>
</template>

<script>
export default {
    name:'navlink',
    props:{
        img:{
            type:String,
            default:'static/svg/Logo.svg'
        },
        title:{
            type:String,
            default:''
        },
        link: {
            type: String,
            default: 'javascript:void(0)'
        },
        a_class: {
            type: String,
            default: ''
        },
        img_class:{
            default:""
        }
    }
}
</script>

<style>
/*
*   NAVIGATION LINKS
*/
.a-nav-link {
    display           : inline-block;
    color             : #000000DE;
    position          : relative;
    -webkit-transition: background .2s ease;
    transition        : background .2s ease;
    height            : 72px;
    padding-right     : 53px;
}

.a-nav-link:hover {
    color     : #000000DE;
    background: #EAF0FC;
}

.a-nav-link .card-img-top {
    -webkit-transform: translateY(-50%);
    transform        : translateY(-50%);
    position         : absolute;
    height           : 56px;
    width            : 100px;
    top              : 50%;
    left             : 0;
}

.a-nav-link .card-body {
    margin-left: 120px;
    padding    : 0;
}

.a-nav-link .card-title {
    color      : #000000DE;
    font-size  : 16px;
    line-height: 72px;
}
/*
*   End of navigation links
*/
</style>