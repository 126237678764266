<template>
    <div :class="{'in-holiday-theme': isHolidayTheme}">
        <nav :class="[a_class, {'with-weekly-quota': showWeeklyQuotaTracker}]" class="navbar navbar-light a-navbar">
            <div class="snow-container" v-if="isHolidayTheme && themeConfig.theme.hasSnow">
                <snowVue style="position: absolute; top: -120px;" :fallCount="80"/>
            </div>
            <template v-if="type == 'main'">
                <router-link class="navbar-brand" style="margin-left: 18px;" :to="$route.path.includes('admin') ? {name:'admindashboard'} : campaigns">
                    <img :src="'/static/svg/' + ($route.path.includes('lawfirm') ? 'CMLogo.svg' : ($route.path.includes('admin')) ? 'Admin_Logo.svg' : 'Logo.svg')" class="d-inline-block align-top" alt="">
                    <img v-if="isHolidayTheme && hasOverrideBrand" class="brand-with-gui-text" :src="`/static/theme/${themeConfig.theme.override?.brand[_gui]}`" alt="stuff">
                    <span v-else :class="{'d-flex align-items-center holiday-navbar-brand': isHolidayTheme }">
                        <div class="d-inline-block holiday-gui-text-con" v-if="isHolidayTheme">
                            <img class="holiday-gui-text" :src="`/static/theme/brand.svg`" alt="stuff">
                        </div>
                        <template v-else>ARTEMIS |</template> {{ ($route.path.includes('lawfirm') ? 'CM' : gui) }}</span>
                </router-link>
            </template>
            <template v-else-if="type == 'addition'">
                <div class="navbar-brand">
                    <div v-if="['listinginfo', 'lawfirmeditlisting'].includes($route.name)" class="ar-tooltip" @mouseenter="hovered.campaign = true" @mouseleave="hovered.campaign = false">
                        <div class="campaign-icon-con position-relative d-inline-block">
                            <img :src="navbar_label ? $parent.getImage('campaigns', navbar_label) : '/static/svg/Campaign Default Photo.svg'" class="campaign-icon" alt="icon">
                        </div>
                        <div class="ar-tooltip-con animated faster" :class="{'fadeIn': hovered.campaign}" v-show="hovered.campaign">
                            <h5 class="ar-tooltip-title">Campaign Name</h5>
                            <span class="campaign-text-full">
                                {{ navbar_label }}
                            </span>
                        </div>
                    </div>
                    <img v-else :src="'/static/svg/' + ($route.path.includes('lawfirm') ? 'CMLogo.svg' : ($route.path.includes('admin')) ? 'Admin_Logo.svg' : 'Logo.svg')" class="d-inline-block align-top mr-3" alt="">
                    <span v-if="['listinginfo', 'lawfirmeditlisting'].includes($route.name)" class="a-navbar-text">
                        <span class="nav-text" title="Listing ID">{{active_listing.rid}}</span>
                        <span class="nav-text" title="Research Status">
                            <img :src="`/static/svg/manual-add/${getStatusIcon(active_listing.qflag_id)}`" class="nav-text-icon">
                            {{$route.path.includes('/lawfirm') && active_listing.status == 'Qualified' ? 'For Review' : active_listing.status}}
                        </span>
                        <div class="ar-tooltip" @mouseenter="hovered.seller = true" @mouseleave="hovered.seller = false">
                            <span class="seller-text">
                                <img src="/static/svg/manual-add/SellerURL_white.svg" class="nav-text-icon d-inline-block align-middle">
                                <b class="font-weight-normal text-truncate w-100 d-inline-block align-middle">{{ active_listing.seller_name }}</b>
                            </span>
                            <div class="ar-tooltip-con animated faster" :class="{'fadeIn': hovered.seller}" v-show="hovered.seller">
                                <h5 class="ar-tooltip-title">Seller Name</h5>
                                <span class="seller-text-full">
                                    {{ active_listing.seller_name }}
                                </span>
                            </div>
                        </div>
                    </span>
                    <span v-else class="a-navbar-text">{{navbar_label}}</span>
                </div>
            </template>

            <div class="a-navbar-nav">
                <div v-if="type == 'main'">
                    <div class="dropdown d-inline-block">
                        <a class="nav-link d-inline-block" href="#" id="userDropdown" style="margin-top: -10px" 
                            :class="{'isSuperAdmin': !$route.path.includes('lawfirm') || ($route.path.includes('lawfirm') && access_level == 7) }"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="hideAutoAssignTracker()"
                        >
                        <div class="user-profile-btn" :title="computed_username.name">
                            <img :src="`${env}/${computed_username.profile_photo}`" style="height: 35px; border-radius: 20px;">
                            <span style="font: normal normal bold 15px Roboto; margin-left: 5px;">
                                <span> {{ computed_username.name }} </span>
                                <i class="fas fa-chevron-down" style="margin-left: 3px"></i>
                            </span>
                        </div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                            <!-- <a class="dropdown-item" href="javascript:void(0);">My Bookmarks</a> -->
                            <!-- <a v-if="!['admin', 'verification'].includes(_gui)" class="dropdown-item" href="javascript:void(0);" @click="showChangePasswordModal()">Account Settings</a> -->
                            <a class="dropdown-item" href="javascript:void(0);" @click="goToUserProfile()"> User Profile </a>
                            <a class="dropdown-item" href="javascript:void(0);" @click="logout()">Logout</a>
                        </div>
                    </div>

                    <div v-if="access_level === 6 && $route.path.includes('lawfirm')" class="dropdown d-inline-block">
                        <i class="fas fa-globe" id="translation"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="translation">
                            <a class="dropdown-item" href="javascript:void(0);" @click="translate('en')">English</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click="translate('ja')">(日本) Japanese</a>
                        </div>
                    </div>

                    <div class="d-inline-block position-relative" style="margin-left: 20px" v-if="$route.path.includes('verification')">
                        <a :href="_searchpage_link" :target="isSearchpage ? '' : '_blank'" title="Go to Search Page">
                            <img src="/static/svg/researched-tracker/search-page.svg">
                            <!-- <ar-button a_class="a-btn-text search-btn ml-2 mr-1" :disabled="isSearchpage">Search Page</ar-button> -->
                        </a>
                    </div>

                     <!-- v-if="$route.path.includes('lawfirm') && access_level == 7" -->
                     <div
                        v-if="$route.path.includes('lawfirm')"
                        class="d-inline-block cm-link-status-monitoring-page" 
                        v-restrict="'cm-link-status-monitoring-page'"
                    >
                        <a :href="_spgMonitoring_link" :target="isSPGMonitoring ? '' : '_blank'" title="Go to Status Monitoring Page">
                            <ar-button a_class="a-btn-text search-btn ml-2 mr-1" :disabled="isSPGMonitoring">
                                <img src="/static/svg/export/Status_Monitoring.svg" alt="spg-monitoring-page">
                            </ar-button>
                        </a>
                    </div>
                    
                    <div class="dropdown d-inline-block ar-notification" v-if="!$route.path.includes('lawfirm')">
                        <a @click="hideDisabledNotifications, hideAutoAssignTracker()" class="nav-link nav-bell d-inline-block" data-toggle="dropdown" id="notifications" href="#" aria-haspopup="false" aria-expanded="false">
                            <i class="far fa-bell" style="margin-left: 10px" />
                            <!-- <img v-else src="/static/svg/christmas/bell.svg" class="christmas-notification-bell" alt="notification"> -->
                            <span v-if="getUnreadNotificationCount" class="badge badge-danger navbar-badge badge-pill">{{ getUnreadNotificationCount }}</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <span class="dropdown-item dropdown-header dropdown-counter">
                                <ar-button 
                                    v-if="getUnreadNotificationCount"
                                    class="position-relative font-weight-bold pl-3 a-btn-text"
                                    style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;"
                                    @click="markAllAsRead_()"
                                >
                                    Mark all as read
                                </ar-button>
                                <div v-else>{{ notifications.length }} Total Notification(s)</div>
                            </span>
                            <div class="dropdown-divider"></div>
                            <div class="notif-main-con" id="notifications-body">
                                <div v-if="notifications.length > 0">
                                    <div
                                        v-for="(notif, index) in notifications"
                                        :key="index"
                                    >
                                        <a @click.prevent="openListingModal(notif)" :class="{'unread': notif?.read_at === null, 'dropdown-item mt-1 mb-1 p-3' : true}">
                                            <div class="position-relative notif-con">
                                                <img :src="getNotificationIcon(notif)" alt="icon">
                                                <div class="notif-details-con">
                                                    <div v-if="!(access_level == 5 || (access_level == 7 && +userRole == 2))">
                                                        <h5 class="d-inline-block">{{ notif?.executor?.first_name }} {{ notif?.executor?.last_name }}</h5>
                                                        <span class="float-right text-muted text-sm">{{ notif?.moment_time }}</span>
                                                    </div>
                                                    <div class="notif-details-con-con" :class="{'cl-notif': (access_level == 5 || (access_level == 7 && +userRole == 2)) && _gui == 'verification'}">
                                                        <p class="mb-2" v-html="parseNotificationText(notif)"></p>
                                                        <template v-if="(access_level == 5 || (access_level == 7 && +userRole == 2)) && _gui == 'verification'">
                                                            <p class="m-0 text-muted">Executor: <span class="text-primary"><i>{{ notif?.executor?.first_name }}</i></span></p>
                                                            <p class="m-0 text-muted">Timestamp: <span class="text-primary">{{ notif?.moment_time }}</span></p>
                                                        </template>
                                                        <p class="m-0 text-muted">Campaign: <span class="text-primary"><i>{{ notif.campaign }}</i></span></p>
                                                        <p class="m-0 text-muted" v-if="(notif.listing || {}).id">Listing: <span class="text-primary"><i>{{ (notif.listing || {}).id || "" }}</i></span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        <div class="dropdown-divider"></div>
                                    </div>
                                </div>
                                <div v-else class="no-notif">You have no notification</div>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="dropdown-item dropdown-footer" v-if="notification_loading">Loading.....</div>
                                <!-- < a v-else :href="`${_gui}/notifications`" target="_blank" rel="noopener" @click="gotoNotificationPage()" class="dropdown-item dropdown-footer">See All Notifications</a> -->
                                <a v-else :href="`${_gui}/notifications`" target="_blank" rel="noopener" class="dropdown-item dropdown-footer">See All Notifications</a>
                        </div>
                    </div>

                    <div class="dropdown d-inline-block" v-if="$route.path.includes('verification') && showWeeklyQuotaTrackerButtonLead">
                        <a class="nav-link d-inline-block position-relative p-0 pl-2" style="top: 7px;">
                            <div class="lead-weekly-quota-tracker researcher-tracker " :class="showResearchedTracker ? 'active' : ''" title="Show Weekly Quota Tracker"
                                @click="showWeeklyQuotaTrackerLead(); furtherResearchNotif = false;"
                                >
                                <span class="lead-weekly-quota-tracker-span researcher-tracker-span ">
                                    <img class="lead-weekly-quota-tracker-icon" src="/static/svg/Infographic-svg/aaa-brands.svg">
                                </span>
                            </div>
                        </a>
                    </div>

                    <div class="dropdown d-inline-block" v-if="$route.path.includes('verification')">
                        <span v-if="access_level == 5 && exhaustedCombos && !newExhausted.status" class="badge badge-danger navbar-badge lead-tracker badge-pill">{{ exhaustedCombos }}</span>
                        <a v-if="showResearchedTrackerButtonListingPage" class="nav-link d-inline-block position-relative">
                            <div class="researcher-tracker" :class="showResearchedTracker ? 'active' : ''" title="Show Tracker Modal"
                                @click="showAutoAssignTracker(); furtherResearchNotif = false;"
                            >
                                <i class="fas fa-bars" />
                                <span class="researcher-tracker-span">
                                    <img class="researcher-tracker-icon" src="/static/svg/researched-tracker/tracker-button.svg">
                                </span>
                            </div>
                            <div style="position: absolute; top: 0; right: 15px; z-index: -1;">
                                <ProgressBarWithBorder :isAutoAssign="true" :progress="currentAssignedCampaign?.listing_count" />
                            </div>
                        </a>
                        <div id="lead-exhausted-tracker-notification" :class="[{'show': newExhausted.status }]" @click="showLeadExhaustedTracker()">
                            <div class="researcher-tracker-logo">
                                <img src="/static/svg/researched-tracker/campaign-bg.svg"
                                    style="position: relative; height: 186px; width: 319px">
                                <div class="exhausted-icon-con">
                                    <div class="researcher-tracker-campaign-con">
                                        <img :src="$parent.getImage('campaigns', newExhausted.details.campaign)" class="researcher-tracker-campaign">
                                    </div>
                                    <img src="/static/svg/lead-tracker/exhausted-icon.svg" class="exhausted-icon" alt="e-icon">
                                </div>
                            </div>
                            <div class="tracker-campaign mb-3">
                                <span class="tracker-campaign-name"> {{ newExhausted.details.campaign }} </span>
                                <span class="tracker-campaign-platform">
                                    <img :src="$parent.getImage('platforms', newExhausted.details.platform)" style="height: 12px; width: 12px">
                                    <span class="tracker-platform-name"> {{ newExhausted.details.platform }} </span>
                                </span>
                            </div>
                            <div class="tracker-text">
                                <span class="tracker-text-desc"> A new combo under you has been tagged as <b>Exhausted</b> by researcher </span>
                                <b class="researcher-name text-danger">{{ newExhausted.details.researcher }}</b>
                            </div>
                        </div>
                        <div id="further-research-notification" :class="[{'show': furtherResearchNotif }]" 
                            @click="furtherResearchNotif = false; showAutoAssignTracker(); $store.dispatch('setResearchedTracker', true);"
                        >
                            <div class="researcher-tracker-logo">
                                <img src="/static/svg/researched-tracker/campaign-bg.svg"
                                    style="position: relative; height: 186px; width: 319px">
                                <div class="exhausted-icon-con">
                                    <div class="researcher-tracker-campaign-con">
                                        <img :src="$parent.getImage('campaigns', forFurtherResearch?.current?.campaign?.name)" class="researcher-tracker-campaign">
                                    </div>
                                    <img src="/static/svg/lead-tracker/exhausted-icon.svg" class="exhausted-icon" alt="e-icon">
                                </div>
                            </div>
                            <div class="tracker-campaign mb-3">
                                <span class="tracker-campaign-name"> {{ forFurtherResearch?.current?.campaign?.name }} </span>
                                <span class="tracker-campaign-platform">
                                    <img :src="$parent.getImage('platforms', forFurtherResearch?.current?.platform?.name)" style="height: 12px; width: 12px">
                                    <span class="tracker-platform-name"> {{ forFurtherResearch?.current?.platform?.name }} </span>
                                </span>
                            </div>
                            <div class="tracker-text">
                                <span class="tracker-text-desc">
                                     A combo has been returned <b style="color:rgb(97, 97, 221)">For Further Research</b> 
                                     and will be shown again after your current combo.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="type == 'addition'">
                    <slot name="header-buttons"></slot>
                </div>
                <div v-else>
                    <slot name="left"></slot>
                </div>
            </div>
        </nav>
        <template v-if="type == 'addition'">
            <slot></slot>
        </template>

        <!-- <portal v-if="access_level == 5"> -->
        <portal v-if="!$featureAccess.isRestricted('v-enable-auto-assignment')">
            <LeadExhaustedTracker ref="leadtrackerRef" />
        </portal>
        <!-- <portal v-if="access_level == 1"> -->
        <portal v-if="showResearcherTrackerModal">
            <ResearchedTrackerModalVue />
        </portal>

        <portal v-if="_gui != 'admin'">
            <PasswordChangeModal />
        </portal>

        <WeeklyQuotaTracker v-if="showWeeklyQuotaTracker" @auto-tracker-on-click="showAutoAssignTracker(); furtherResearchNotif = false;" />
    </div>
</template>

<script>
import button from './Button'
import axios from '../../axiosMime'
import {mapState, mapActions, mapGetters} from 'vuex'
import {eventBus} from '../../main'
import moment from 'moment';
import SnowVue from './Snow';
import NotificationDetail from "@/components/layouts/NotificationDetail";
import ProgressBarWithBorder from "@/components/layouts/ProgressBarWithBorder"
import { listen } from '../../events'
import { getGuiSession } from '@/utils/session';
import config from "../../../public/static/theme/conf.json"
export default {
    name: "ar-navbar",
    components: {
    "ar-button": button,
    "snowVue": SnowVue,
    LeadExhaustedTracker: () => import("@/components/layouts/modals/LeadExhaustedTrackerModal"),
    ResearchedTrackerModalVue: () => import("@/components/layouts/modals/ResearchedTrackerModal"),
    PasswordChangeModal: () => import("@/components/layouts/modals/PasswordChangeModal"),
    WeeklyQuotaTracker: () => import("@/components/layouts/WeeklyQuotaTracker"),
    "ProgressBarWithBorder": ProgressBarWithBorder,
},
    props:{
        type: {
            type: String,
            default:''
        },
        gui: {
            type: String,
            default: ''
        },
        user: {
            type: String,
            default:''
        },
        a_class: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: 'javascript:void(0)'
        },
        exit:{
            type:String,
            default:''
        },
        label: {
            type:String,
            default:''
        }
    },
    async mounted() {
        listen(message => {
            if (message.type == 'mark-as-further-research') {
                const {id} = getGuiSession()
                if(message?.data?.researchers.includes(id)) {
                    this.forFurtherResearch = message.data
                    this.furtherResearchNotif = true
                    this.getUserAssignments()
                }
            }
        }),

        this.getUserProfile();
        this.getUserAssignments();

        if (!this.isWeeklyQuotaTrackerRestricted && getGuiSession()?.id)
            this.getUserQuota();
        
        document.querySelectorAll('.dropdown-counter > .a-btn-text').forEach(element => element.stopPropagation());
        
        if (!this.$route.path.includes('lawfirm')) {
            document.getElementById('notifications-body').addEventListener("scroll", this.refreshNotification);
        }
    },
    watch: {
        isWeeklyQuotaTrackerRestricted(val) {
            if (!val && getGuiSession()?.id)
                this.getUserQuota()
        },
        notifications() {
            this.hideDisabledNotifications()
        },
        leadNotifications: {
            async handler(leadNotifications) {
                if(this.$route.path.includes('verification')) {
                    for (const item of leadNotifications) {
                        const isNotifyLead = item.listing.qflag_id === 2
                            && item.general_history.text.includes('Tagged listing to Recheck')
                            && item.notified_at === null

                        if (!isNotifyLead) {
                            break
                        }

                        await this.$toast(NotificationDetail, {
                            position              : 'top-right',
                            timeout               : 10000,
                            closeOnClick          : true,
                            pauseOnFocusLoss      : true,
                            pauseOnHover          : true,
                            draggable             : true,
                            draggablePercent      : 0.6,
                            showCloseButtonOnHover: true,
                            hideProgressBar       : false,
                            closeButton           : 'button',
                            icon                  : 'far fa-bell text-info',
                            rtl                   : false,
                            item                  : {...item}
                        })

                        this.hideDisabledNotifications()

                        //Audio Source: https://www.zedge.net/ringtone/b57b967a-6264-3bfe-86f4-ec3d58983ded
                        const audio = new Audio('/static/mp3/notification.mp3')
                        await audio.play()
                            .catch(_ => console.warn('This browser does not support audio auto play'))
                        this.markAsNotified(item.id)
                    }
                }
            },
            deep: true
        },
        async 'newExhausted.status'(value) {
            if (value) {
                await sleep(20000) 
                this.newExhausted.status = false
            }
        }
    },
    computed:{
        ...mapGetters(['getUnreadNotificationCount', 'isHolidayTheme']),
        ...mapState(['notifications', 'leadNotifications','showResearchedTracker', 'currentAssignedCampaign', 'waitingTracker', 'weekly_quota', 'user_profile_vuex']),
        themeConfig: () => config,
        hasOverrideBrand() {
            return this.themeConfig.theme.override?.brand && this.themeConfig.theme.override?.brand[this._gui]
        },
        showResearcherTrackerModal() {
            return !this.$featureAccess.isRestricted('v-enable-auto-assignment') || !this.isWeeklyQuotaTrackerRestricted && !['listing', 'search'].includes(this.$route.name)
        },
        isWeeklyQuotaTrackerRestricted() {
            return this.$featureAccess.isRestricted('v-enable-weekly-quota-tracker')
        },
        showWeeklyQuotaTracker() {
            return !this.isWeeklyQuotaTrackerRestricted && ['listing', 'search'].includes(this.$route.name)
        },
        isCM() {
            return this.$route.path.includes('/lawfirm')
        },
        showResearchedTrackerButtonListingPage() {
            if (['listing', 'search'].includes(this.$route.name) && !this.isWeeklyQuotaTrackerRestricted) {
                if (this.access_level == 5 && !this.$featureAccess.isRestricted('v-enable-auto-assignment'))
                    return true

                return false
            }

            return (!this.$featureAccess.isRestricted('v-enable-auto-assignment') || (!this.isWeeklyQuotaTrackerRestricted && this.access_level != 5))
        },
        showWeeklyQuotaTrackerButtonLead() {
            return this.access_level == 5 && !this.isWeeklyQuotaTrackerRestricted && !['listing', 'search'].includes(this.$route.name)
        },
        username(){
            let gui = location.pathname.includes("verification") ? "ver" : location.pathname.includes("lawfirm") ? "lawfirm" : "admin"
            let username = ""
            if(JSON.parse(localStorage.getItem(gui)) != null){
                let local = JSON.parse(localStorage.getItem(gui))
                username = local.first_name + " " + (local.last_name != null ? local.last_name : "")
            }
            return username
        },
        exhaustedCombos() {
            return this.access_level == 5 ? this.$refs?.leadtrackerRef?.combos?.length : 0
        },
        campaigns(){
            let path = {}
            if(this.$route.path.split('/')[1] == "lawfirm"){
                path = {name:"lawfirmcampaigns"}
            }else{
                path = {name:"campaigns"}
            }
            return path
        },
        navbar_label(){
            let label = 'Manual Addition'
            if(this.label != ""){
                return this.label
            }
            if(this.$route.query.action == "edit"){
                label = this.$store.state.is_active_list_ready ? this.$store.state.active_listing_info[0].campaign : ""
            }
            if (this.$route.path.includes("admin")) {
                if (this.$route.path.includes("edit")) {
                    label = "Edit "
                } else {
                    label = "Add "
                }

                if (this.$route.path.includes("campaigns")) {
                    label += "Campaign"

                    if (this.$route.path.includes("edit"))
                        label += " Details"
                }

                if (this.$route.path.includes("lf")) {
                    label += "Lawfirm"

                    if (this.$route.path.includes("edit"))
                        label += " Details"
                }
            }
            return label
        },
        isSearchpage() {
            return this.$route.name == "search"
        },
        isSPGMonitoring() {
            return this.$route.name == "lawfirmsubmissionspackmonitoring"
        },
        active_listing(){
            let active_listing = {}
            if(this.$store.state.is_active_list_ready){
                active_listing =  this.$store.state.active_listing_info[0]
            }
            return active_listing
        },
      _gui() {
        let path = this.$route.path
        return path.includes('verification')
          ? 'verification'
          : ( path.includes('lawfirm')
                ? 'lawfirm'
                : 'admin')

      },
      _spgMonitoring_link() {
          return !this.isSPGMonitoring ? this.$router.resolve({name: 'lawfirmsubmissionspackmonitoring'}).href : 'javascript:void(0);'
      },
      _searchpage_link() {
          return !this.isSearchPage ? this.$router.resolve({name: 'search', query: {status: 'All', platform: 0}}).href : 'javascript:void(0);'
      },
      access_level(){
          return JSON.parse(localStorage.getItem(this.isCM ? 'lawfirm' : 'ver'))?.access_level
      },
      userRole() {
          return JSON.parse(localStorage.getItem(this.isCM ? 'lawfirm' : 'ver'))?.role
      },
      translateTo() {
        return vm.locale === 'en' ? '日本' : 'English';
      },
      computed_username() {
        let time = new Date().getTime()
        return {
            name: `${this.user_profile_vuex?.first_name ?? ''} ${this.user_profile_vuex?.last_name ?? ''}`,
            profile_photo: `${this.user_profile_vuex?.profile_photo}?t=${time}`
        };
      },
      env() {
        return process.env.VUE_APP_URL
      }
    },
    data(){
        return{
            redirect:this.exit,
            notification_loading : false,
            hovered: {
                seller: false,
                campaign: false
            },
            currentProgress: 10,
            newExhausted: {
                details: {
                    platform: '',
                    campaign: '',
                    researcher: ''
                },
                status: false
            },
            forFurtherResearch: null,
            furtherResearchNotif: false
        }
    },
    methods:{
        ...mapActions(['markAsRead', 'getNotifications', 'markAllAsRead', 'resetPostFilingFilters', 'getUserQuota', 'getUserProfile']),
        showChangePasswordModal() {
            $('#user-password-update').modal({ backdrop: 'static', keyboard: false })
        },
        translate(locale) {
            // vm.locale = vm.locale === 'en' ? 'ja' : 'en';
            vm.locale = locale;
        },
        showLeadExhaustedTracker() {
            this.newExhausted.status = false
            this.$refs?.leadtrackerRef?.getExhaustedCombos()
            this.$refs?.leadtrackerRef?.getVerifiedExhaustedCombos()
            $('#lead-exhausted-tracker').modal({ backdrop: 'static', keyboard: false })
        },
        parseNotificationText(notif) {
            if (this.access_level != 5 && !(this.access_level == 7 && +this.userRole == 2) && this._gui == 'verification')
                return notif?.general_history?.text
            
            const getOwner = (notif) => {
                let name = notif?.listing?.owner?.first_name

                if (notif?.listing?.owner?.last_name?.trim() != '' && notif?.listing?.owner?.last_name?.trim() != null)
                    name += ` ${notif?.listing?.owner?.last_name}`

                return name
            }

            const getExecutor = (notif) => {
                let name = notif?.executor?.first_name

                if (notif?.executor?.last_name?.trim() != '' && notif?.executor?.last_name?.trim() != null)
                    name += ` ${notif?.executor?.last_name}`

                return name
            }

            let output = ""
            let text = notif?.general_history?.text
            let parsed = { from: '', to: '' }
            let field = notif?.general_history?.field

            if (field == 'payment_account') {
                let split = text.split('<br/>to<br/>')
                parsed.to = split[1]
                split = split[0].split('from:')
                parsed.from = split[1]
                output = `<b>${getOwner(notif)}'s</b> listing payment account was updated by <b>${getExecutor(notif)}</b> from ${parsed.from} <br/>to<br/> ${parsed.to}`
            } else if (field == 'qflag_id') {
                if (text.includes('ver-only')) {
                    let part = text.split('</div>')
                    let split = part[1].split('of:')
                    let status = split[0].split('</b> to <b>')[1].split('</b>')[0]
                    parsed.to = split[1]
    
                    output = `<b>${getOwner(notif)}'s</b> listing was tagged by <b>${getExecutor(notif)}</b> to <b>${status}</b> with the reason(s) of: ${parsed.to.replace('<ul>', '<ul class="pl-3">')} ${part[0].replace("<div class='ver-only'>", "<div class='ver-only mt-2'>") + '</div>'}`
                } else {
                    let breaks = text.split('<br>')
                    if (breaks.length) {
                        let split = breaks[0].split(' to ')
                        parsed.to = split[1] + `<br/> ${breaks.splice(1).join('<br/>')}`
                        split = split[0].split('from')
                        parsed.from = split[1]
                    } else {
                        let split = text.split(' to ')
                        parsed.to = split[1]
                        split = split[0].split('from')
                        parsed.from = split[1]
                    }
                    output = `<b>${getOwner(notif)}'s</b> listing status was updated by <b>${getExecutor(notif)}</b> from ${parsed.from} to ${parsed.to}`
                }
            } else if (field == 'listing_reasons') {
                let split = text.split('of:')
                let status = split[0].split(' to ')[1].split(' with the ')[0]
                parsed.to = split[1]

                output = `<b>${getOwner(notif)}'s</b> listing was tagged by <b>${getExecutor(notif)}</b> to <b>${status}</b> with the reason(s) of: ${parsed.to.replace('<ul>', '<ul class="pl-3">')}`
            } else if (field == 'user_id') {
                let split = text.split('<br/>')
                let researcherIndex = split.findIndex(s => s.includes('Researcher:'))
                parsed.to = split[researcherIndex]?.split(' to ')[0]?.replace('Researcher: ', '')  ?? ''
                split = split.splice(researcherIndex + 1)
                split = split.splice(1)
                
                if (split.length) parsed.to = `${parsed.to} <br/>${split.join('<br/>')}`  

                output = `<b>${getOwner(notif)}'s</b> listing was reassigned by <b>${getExecutor(notif)}</b> to ${parsed.to}`
            } else {
                if (text.includes(' with the value ') && !text.includes('from')) {
                    let split = text.split('with the value')
                    parsed.to = split[1]
                    
                    output = `<b>${getOwner(notif)}'s</b> listing ${field.split('_').join(' ')} was updated by <b>${getExecutor(notif)}</b> to ${parsed.to}`
                } else {
                    let split = text.split(' to ')
                    parsed.to = split[1]
                    split = split[0].split('from')
                    parsed.from = split[1]

                    output = `<b>${getOwner(notif)}'s</b> listing ${field.split('_').join(' ')} was updated by <b>${getExecutor(notif)}</b> from ${parsed.from} to ${parsed.to}`
                }
            }

            return output
        },
        hideDisabledNotifications() {
            setTimeout(() => {
                $('.floating-notification .not-notifiable').hide()
                $('.notif-details-con-con .not-notifiable').hide()
            }, 100)
        },
        markAsNotified(id) {
            setTimeout(() => this.$http.patch(`/notification/notified/${id}`, {}, this.$session.get('ver_headers')), 3000)
        },
        getNotificationIcon(notification) {
            if (notification.general_history?.field == 'user_id' ||
            notification.general_history?.field == 'qa_user_id' ||
            notification.general_history?.field == 'qflag_id') {
                return '/static/svg/notifications/' + (notification?.status == 'Recheck' ? 'For Rework' : notification?.status) + '.svg';
            }

            return '/static/svg/notifications/Update.svg';
        },
        async refreshNotification({target: {id, scrollTop, clientHeight, scrollHeight}}) {
            if (!this.notification_loading && scrollTop + clientHeight >= scrollHeight) {
                this.notification_loading = true;
                if (!this.$route.path.includes('/lawfirm')) {
                    await this.getNotifications().then(notification => {
                        this.notification_loading = false;
                        if (notification.length === 0) {
                            document.getElementById(id).removeEventListener('scroll', this.refreshNotification);
                        }
                    });
                }
            }
        },
        async logout(){
            let headers = {}
            // resetting global search when logging out
            this.$store.dispatch('isGlobalSearch', false) 
            this.$parent.sidebar_campaigns = []
            this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-sidebar')[0].campaigns = []
            this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].dem_statuses = []
            if(location.pathname.includes("verification")){
                headers = JSON.parse(localStorage.getItem("ver")).headers
            }else if(location.pathname.includes("lawfirm")){
                    localStorage.removeItem('gui')
                    localStorage.removeItem('token')
                    localStorage.removeItem('headers')
                    headers = JSON.parse(localStorage.getItem("lawfirm")).headers
            } else if (this.$route.path.includes("/admin")) {
                headers = JSON.parse(localStorage.getItem("admin")).headers
            }
            await axios.delete(process.env.VUE_APP_URL+'/logout',JSON.parse(headers))
                .then(res=>{
                    if(location.pathname.includes("verification")){
                        localStorage.removeItem("ver")
                    }else if (location.pathname.includes("lawfirm")) {
                        localStorage.removeItem("token")
                        localStorage.removeItem("headers")
                        localStorage.removeItem("lawfirm")
                    } else if (location.pathname.includes("/admin")) {
                        localStorage.removeItem("admin")
                        this.$session.remove("admin_headers")
                        this.$session.remove("admin")
                    }
                    this.$router.push(`/${this.$route.path.split('/')[1]}`)
                })
            this.resetPostFilingFilters()
            this.hideAutoAssignTracker()
            // window.location.reload()
        },
        navigate(page) {
            this.$router.push(page)
        },
        openListingModal(notification) {
            this.markAsRead(notification).then((response) => {
                if (this.$route.path.includes('/verification')) {
                    // window.location.href = `${window.location.origin}/verification/my/listings/campaign/${notification.listing.campaign_id}?status=${encodeURI(notification.current_status)}&platform=0&keywords=${(notification.listing || {}).id || ""}`;
                    // const url = `${window.location.origin}/verification/my/listings/campaign/${notification.listing.campaign_id}?status=${encodeURI(notification.current_status)}&platform=0&keywords=${(notification.listing || {}).id || ""}`
                    const url = `${window.location.origin}/verification/search?status=All&platform=0&lid=${notification.listing.id}`
                    window.open(url, '_blank')
                }
                else if (this.$route.path.includes('/lawfirm')) {
                    if (notification.download_link != "") {
                        this.openSPGMonitoringPage(notification) 
                    } else if (notification.client_listing_id != null) {
                        let campaign = notification.general_history.campaign_id
                        this.$router.push({name: 'lawfirmcampaigncounterfeitchecker', params: { campaign_id: campaign }, query: { id: notification.client_listing_id }})
                    } else {
                        let status = notification.current_status;
                        if (status == 'Qualified') {
                            status = 'For Review';
                        }
                        else if (status == 'Hacked') {
                            status = 'Auto Qualified';
                        }
                        this.$router.push({name: 'lawfirmlisting', params: {campaign_id: notification.listing.campaign_id, status: status, case_id: 0}, query: {listing_id: (notification.listing || {}).id || ""}});
                    }
                }
            });
        },
        markAllAsRead_() {
            this.$parent.showSnackbar('Processing...', true);
            this.markAllAsRead().then(success => {
                if (!success) {
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                }
                else {
                    this.$parent.showSnackbar('Done', false, 'success', 'fas fa-check-circle');
                }
            });
        },
        gotoNotificationPage() {
            if (this.$route.path.includes('/admin')) {
                this.$router.push({name: 'adminnotifications'})
            } else if (this.$route.path.includes('/lawfirm')) {
                this.$router.push({name: "lawfirmnotifications"})
            } else {
                this.$router.push({name: 'notifications'})
            }
        },
        getStatusIcon(id) {
            let icon = ""
            switch (id) {
                case 1:	
                    icon = "Icon_Researched"
                    break;
                case 2:
                    icon = "Icon_ForRework"
                    break;
                case 3:
                    icon = "Icon_Qualified"
                    break;
                case 4:	
                    icon = "Icon_WIP"
                    break;
                case 8:	
                    icon = "Icon_Reworked"
                    break;
                case 30: 
                    icon = "Icon_Hacked"
                    break;
                case 31:
                    icon = "Icon_Monitoring"
                    break;
                case 32:
                    icon = "Icon_Accepted"
                    break;
                case 33:
                    icon = "Icon_Rejected"
                    break;
                case 34:
                    icon = "Icon_Filed"
                    break;
                case 35:
                    icon = "Icon_Submitted"
                    break;
                case 97: 
                    icon = "Icon_Paid"
                    break;
                case 98: 
                    icon = "Icon_HasPotential"
                    break;
                case 99:
                    icon = "Icon_Invalid"
                    break;
                default:
                    break;
            }
            return `${icon}.svg`
        },
        openSPGMonitoringPage(notification) {
            const url = `${window.location.origin}/lawfirm/submissions-pack-monitoring?dir_name=${notification.download_link}`
            window.open(url, '_blank')
        },
        showAutoAssignTracker() {
            if (this.access_level == 5) {
                this.showLeadExhaustedTracker()
            }
            else {
                if(this.showResearchedTracker == false) {
                    this.$store.dispatch('setResearchedTracker', true)
                    setTimeout(() => {
                        this.$store.dispatch('setWaitingTracker', true)
                    }, 4000)
                }
                else if (this.showResearchedTracker == true) {
                    this.$store.dispatch('setResearchedTracker', false)
                }
            }
            // else if (this.access_level == 1) {
            //     if(this.showResearchedTracker == false) {
            //         this.$store.dispatch('setResearchedTracker', true)
            //         setTimeout(() => {
            //             this.$store.dispatch('setWaitingTracker', true)
            //         }, 4000)
            //     }
            //     else if (this.showResearchedTracker == true) {
            //         this.$store.dispatch('setResearchedTracker', false)
            //     }
            // }
        },
        showWeeklyQuotaTrackerLead() {
            if(this.showResearchedTracker == false) {
                this.$store.dispatch('setResearchedTracker', true)
                setTimeout(() => {
                    this.$store.dispatch('setWaitingTracker', true)
                }, 4000)
            }
            else if (this.showResearchedTracker == true) {
                this.$store.dispatch('setResearchedTracker', false)
            }
        },
        hideAutoAssignTracker() {
            this.$store.dispatch('setResearchedTracker', false)
        },
        getUserAssignments() {
                this.$store.dispatch('userAssignments')
        },
        goToUserProfile() {
            if (this.$route.path.includes('verification')) {
                this.$router.push({name:"user-profile-ver"})
            }
            
            if(this.$route.path.includes('lawfirm')) {
                this.$router.push({name:"user-profile-lf"})
            }

            if(this.$route.path.includes('admin')) {
                this.$router.push({name:"user-profile-admin"})
            }
        }
    },
    destroyed() {
        clearInterval(window.moment_updater);
    }
}
</script>

<style scoped>
    .a-navbar {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        /* box-shadow: 0px 2px 5px #0000000D; */
        padding: 9px 30px 8px 13px;
        position: relative;
        height: 57px;
        z-index: 2;
        border-bottom: 1px solid #dee2e6;
    }

    .a-navbar .navbar-brand {
        letter-spacing: 3.2px;
        color: #3E88DA;
        font-weight: 500;
        font-size:16px;
        line-height: 31px;
        padding: 0;
        /* margin-left: 18px; */
    }

    .a-navbar.with-weekly-quota {
        width: calc(100% - 322px);
    }

    .a-navbar .navbar-brand:hover {
        color: #3E88DA;
    }

    .a-navbar .navbar-brand .nav-text-icon {
        height: 16px;
        width: 16px;
        margin-right: 10px;
        position: relative;
        top: -2px;
    }

    .a-navbar .navbar-brand .campaign-icon {
        height: 40px;
        width: 40px;
        border: 1px solid #3E88DA;
        border-radius: 20px;
        position: relative;
    }

    .a-navbar .navbar-brand .nav-text,
    .a-navbar .navbar-brand .seller-text {
        height: 28px;
        padding: 0 25px;
        line-height: 30px;
        border: 1px solid #3E88DA;
        border-radius: 20px;
        margin-left: 14px;
        position: relative;
        font-size: 14px;
        color: #3E88DA;
        display: inline-block;
        vertical-align: middle;
    }

    .a-navbar .navbar-brand .seller-text {
        max-width: 230px;
        background: #3E88DA;
        color: #ffff;
        padding-left: 25px;
        padding-right: 40px;
    }

    .a-navbar .navbar-brand .seller-text-full,
    .a-navbar .navbar-brand .campaign-text-full {
        width: 100%;
        display: block;
        min-height: 28px;
        padding: 10px 20px 10px 47px;
        border: 1px solid #70707042;
        border-radius: 20px;
        background: #84b3e7;
        color: #ffff;
        font-size: 12px;
        font-weight: 500 !important;
        word-break: break-all;
        white-space: normal;
        line-height: 18px;
        letter-spacing: 0.24px;
        position: relative;
    }

    .a-navbar .navbar-brand .seller-text-full::before,
    .a-navbar .navbar-brand .campaign-text-full::before {
        content: "";
        position: absolute;
        left: 20px;
        top: 50%;
        height: 16px;
        width: 16px;
        background: url('/static/svg/manual-add/SellerURL_white.svg');
        background-size: cover;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .a-navbar .navbar-brand .campaign-text-full::before {
        background: url('/static/svg/manual-add/SellerURL_white.svg');
        background-size: cover;
    }

    .a-navbar .navbar-brand .campaign-icon-con::before,
    .a-navbar .navbar-brand .nav-text::before,
    .a-navbar .navbar-brand .seller-text::before {
        content: "";
        position: absolute;
        height: 1px;
        width: 15px;
        background: #3E88DA;
        right: -15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .a-navbar .navbar-brand .seller-text::after {
        content: "";
        position: absolute;
        height: 12px;
        width: 12px;
        background: #3E88DA;
        border-radius: 100%;
        right: -27px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .ar-tooltip {
        position: relative;
        display: inline-block;
    }

    .ar-tooltip-con {
        width: 346px;
        padding: 20px 14px 24px;
        border-radius: 16px;
        border: 1px solid #7070703D;
        background: #ffff;
        position: absolute;
        left: 20px;
        top: 21px;
        z-index: 12;
    }

    .ar-tooltip-title {
        font-weight: 700;
        font-size: 12px;
        color: #888888;
        letter-spacing: 2.4px;
        text-transform: uppercase;
    }

    .a-navbar .a-navbar-text {
        color: #515365 !important;
        letter-spacing: initial;
        font-weight: 400;
        font-size: 20px;
    }

    .a-navbar .navbar-brand img {
        /* margin-right:10px; */
        margin-right: 1px;
        height: 30px;
        width: 30px;
    }

    .a-navbar .a-navbar-nav a:not(.dropdown-item) {
        color: #515365;
        font-weight: 500;
    }

    .a-navbar .search-btn {
        color: #383A3D;
    }

    #userDropdown, #translation {
        position: relative;
    }

    #userDropdown.isSuperAdmin:after, #translation:after{
        content: '';
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background: #7070701C;
        position: absolute;
        height:56px;
        width:1px;
        top: 50%;
        right: 0;
    }

    #userDropdown i, #translation i {
        margin-left: 10px;
        opacity: 0.57;
    }
    .align-campaign {
        max-width     : 200px;
        display       : inline-block;
        vertical-align: middle;
    }

    .ar-notification .dropdown-menu-lg {
        max-width: initial;
        width: 420px;
    }

    .ar-notification .dropdown-menu .dropdown-item.unread {
        background: #e8e8e8;
    }
    
    .dropdown-item:hover {
        background-color:#dfdede !important;
    }

    .ar-notification .dropdown-counter,
    .ar-notification .dropdown-footer {
        padding: 14px;
    }

    .ar-notification .badge {
        top: 2px;
        padding: 3px 4px 2px;
    }

    .ar-notification .notif-main-con {
        min-height: 0;
        max-height: 350px;
        overflow-y: auto;
    }

    .ar-notification .notif-con {
        padding-left: 58px;
        cursor: pointer;
    }

    .ar-notification .notif-con img {
        position:absolute;
        height: 40px;
        width: 40px;
        left:0;
        top: 0;
    }

    .ar-notification .notif-details-con h5 {
        color: #515365;
        font-size: 16px;
        margin-bottom: 2px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 60%;
    }

    .ar-notification .notif-details-con .text-muted {
        color: #999a9b !important;
        font-size: 11px !important;
    }

    .ar-notification .notif-details-con-con {
        letter-spacing: 0.3px;
        font-size: 11px;
    }

    .ar-notification .notif-details-con-con.cl-notif {
        font-size: 13px;
        width: 100%;
    }

    /* 
    .ar-notification .notif-details-con-con.cl-notif p.m-0.text-muted {
        display: inline-block;    
    }
    */

    .no-notif {
        font-size: 14px;
        padding:14px;
        text-align:center;
    }

    * >>> .a-btn-text:hover {
        background-color: transparent !important;
        text-decoration: underline;
    }

    .notif-details-con-con {
        word-break: break-word;
    }

    /* .in-holiday-theme .a-navbar {
        background: #F9D9C3;
    } */

    .in-holiday-theme .a-navbar .brand-with-gui-text {
        width: 302px;
        margin-right: 0;
        height: auto;
        position: absolute;
        top: 8px;
    }

    .in-holiday-theme .snow-container {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .in-holiday-theme .christmas-notification-bell {
        height: auto;
        width: 18px;
    }

    /* .gui-admin .in-holiday-theme .a-navbar {
        background: #F4CCC0;
    } */

    .in-holiday-theme .a-navbar .holiday-navbar-brand {
        position: absolute;
        margin-top: -38px;
        text-transform: uppercase;
        font-weight: 900;
        color: #E3707F;
        letter-spacing: -0.1px;
    }

    .in-holiday-theme .a-navbar .holiday-gui-text {
        width: 177px;
        margin-right: 14px;
        height: auto;
        position: relative;
        top: -10px;
        margin-left: 35px;
    }

    .in-holiday-theme .a-navbar .holiday-gui-text-con {
        position: relative;
        margin-right: 10px;
    }

    .in-holiday-theme .a-navbar .holiday-gui-text-con::before {
        content: "";
        position: absolute;
        right: 0;
        height: 40%;
        width: 1px;
        background: #E3707F;
        top: 28%;
    }

    .gui-admin .in-holiday-theme .a-navbar .brand-with-gui-text {
        width: 325px;
    }

    /*
    *   ADMIN VARIATION
    */
    .gui-admin .a-navbar .navbar-brand {
        color: #480048;
    }

    .gui-admin .a-navbar .navbar-brand:hover {
        color: #480048;
    }

    .gui-admin .in-holiday-theme .a-navbar .holiday-gui-text-con::before {
        background: #480048;
    }

    .gui-admin .in-holiday-theme .a-navbar .holiday-navbar-brand {
        color: #480048;
    }

    /* RESEARCHER'S TRACKER START */

    .researcher-tracker {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 15px;
        padding: 2px 4px;
        /* background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #B8D2F1; */
        border-radius: 20px;
        margin-top: -8px;
        transition: .3s;
        position: relative;
    }
    .researcher-tracker.active {
        cursor: pointer;
        background: #c1c1c140 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #00000029;
        /* border: 2px solid #B8D2F1; */
        transition: .3s;
    }
    .researcher-tracker.active .researcher-tracker-icon {
        transform: rotate(-15deg) scale(1.2);
        transition: .3s;
    }
    .researcher-tracker:hover {
        cursor: pointer;
        background: #c1c1c140 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #00000029;
        /* border: 2px solid #B8D2F1; */
        transition: .3s;
    }

    .researcher-tracker:hover .researcher-tracker-icon {
        transition: .3s;
        transform: rotate(-15deg) scale(1.2);
    }
    .researcher-tracker-span {
        border-radius: 50%;
        background: #6EB4F9;
        padding: 5px;
    }

    .researcher-tracker-icon {
        transition: .3s;
    }

    .researcher-tracker i {
        padding-left: 8px;
    }

    /* RESEARCHER'S TRACKER END */

    .navbar-badge.lead-tracker {
        top: -5px;
        right: 8px;
        z-index: 2;
        padding: 3px 5px;
    }

    #lead-exhausted-tracker-notification {
        background: #ffff;
        width: 295px;
        height: 325px;
        position: absolute;
        right: 15px;
        top: 43px;
        border-radius: 12px;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
        -webkit-transform: scale(0);
        transform: scale(0);
        transform-origin: right top;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
        cursor: pointer;
    }

    #lead-exhausted-tracker-notification.show {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    #lead-exhausted-tracker-notification.show:hover {
        background: #F5F5F5;
    }

    #lead-exhausted-tracker-notification .researcher-tracker-logo {
        height: 134px;
        display: flex;
        justify-content: center;
    }
    
    #lead-exhausted-tracker-notification .researcher-tracker-logo .exhausted-icon {
        position: absolute;
        right: -12px;
        bottom: -10px;
        width: 32px;
        height: 32px;
    }

    #lead-exhausted-tracker-notification .researcher-tracker-campaign-con {
        overflow: hidden;
        height: 85px;
        width: 85px;
        border-radius: 100%;
    }

    #lead-exhausted-tracker-notification .exhausted-icon-con {
        position: absolute;
        top: 28.7%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    #lead-exhausted-tracker-notification .researcher-tracker-campaign {
        height: 100%;
        width: 100%;
        transform: scale(1.2)
    }
    #lead-exhausted-tracker-notification .tracker-campaign {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 10px;
        margin-top: 25px;
        position: relative;
        z-index: 2;
    }
    #lead-exhausted-tracker-notification .tracker-campaign-name {
        font: normal normal bold 15px/21px Roboto;
        letter-spacing: 0.36px;
        color: #515365;
    }
    #lead-exhausted-tracker-notification .tracker-platform-name {
        font: normal normal bold 10px/21px Roboto;
        letter-spacing: -0.2px;
        color: #383A3D;
    }
    #lead-exhausted-tracker-notification .tracker-campaign-platform {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #383A3D26;
        border-radius: 4px;
        padding: 2px 10px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 2px;
        width:fit-content;
    }
    #lead-exhausted-tracker-notification .tracker-text {
        text-align: center;
        font: normal normal bold 14px/18px Roboto;
        letter-spacing: 0px;
        color: #515365;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    #lead-exhausted-tracker-notification .tracker-text-desc {
        margin: 0 auto;
        padding: 0 20px;
        display: block;
    }

    #lead-exhausted-tracker-notification .researcher-name {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #383A3D26;
        border-radius: 4px;
        padding: 2px 10px;
        display: inline-block;
        text-align: center;
        margin: 10px auto 0;
    }

    /*FURTHER RESEARCH NOTIFICATION */

    #further-research-notification {
        background: #ffff;
        width: 295px;
        height: 325px;
        position: absolute;
        right: 15px;
        top: 43px;
        border-radius: 12px;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
        -webkit-transform: scale(0);
        transform: scale(0);
        transform-origin: right top;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
        cursor: pointer;
    }

    #further-research-notification.show {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    #further-research-notification.show:hover {
        background: #F5F5F5;
    }

    #further-research-notification .researcher-tracker-logo {
        height: 134px;
        display: flex;
        justify-content: center;
    }
    
    #further-research-notification .researcher-tracker-logo .exhausted-icon {
        position: absolute;
        right: -12px;
        bottom: -10px;
        width: 32px;
        height: 32px;
    }

    #further-research-notification .researcher-tracker-campaign-con {
        overflow: hidden;
        height: 85px;
        width: 85px;
        border-radius: 100%;
    }

    #further-research-notification .exhausted-icon-con {
        position: absolute;
        top: 28.7%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    #further-research-notification .researcher-tracker-campaign {
        height: 100%;
        width: 100%;
        transform: scale(1.2)
    }
    #further-research-notification .tracker-campaign {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 10px;
        margin-top: 25px;
        position: relative;
        z-index: 2;
    }
    #further-research-notification .tracker-campaign-name {
        font: normal normal bold 15px/21px Roboto;
        letter-spacing: 0.36px;
        color: #515365;
    }
    #further-research-notification .tracker-platform-name {
        font: normal normal bold 10px/21px Roboto;
        letter-spacing: -0.2px;
        color: #383A3D;
    }
    #further-research-notification .tracker-campaign-platform {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #383A3D26;
        border-radius: 4px;
        padding: 2px 10px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 2px;
        width:fit-content;
    }
    #further-research-notification .tracker-text {
        text-align: center;
        font: normal normal bold 14px/18px Roboto;
        letter-spacing: 0px;
        color: #515365;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    #further-research-notification .tracker-text-desc {
        margin: 0 auto;
        padding: 0 20px;
        display: block;
    }

    #further-research-notification .researcher-name {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #383A3D26;
        border-radius: 4px;
        padding: 2px 10px;
        display: inline-block;
        text-align: center;
        margin: 10px auto 0;
    }

    .lead-weekly-quota-tracker-span {
        width: 25px;
        height: 25px;
        overflow: hidden;
        display: inline-block;
    }

    .lead-weekly-quota-tracker-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%) scale(0.15);
        transform: translate(-50%, -50%) scale(0.15);
    }
    .user-profile-btn {
        padding: 5px;
        border-radius: 5px;
        transition: 0.3s;
    }
    .user-profile-btn:hover {
        background: #EBEBEB 0% 0% no-repeat padding-box;
        border-radius: 5px;
        transition: 0.3s;
    }
</style>
