<template>
    <div class="more-filters-con" :class="{'expanded':expanded}">
        <h6 
            class="position-absolute d-inline-block more-filters-header"
            @click="expanded = !expanded" 
        >
            <i class="fas fa-chevron-up more-filters-header-icon" :class="{'expanded':expanded}"></i> {{ !expanded ? header[0] : header[1] }}
        </h6>
        <div id="reset-more-filter-btn" class="position-absolute" v-if="!expanded">
            <ar-button :a_class="`btn-invalid ${!expanded ? 'animated fadeIn' : ''}`" @click="$emit('reset_filters')">Reset</ar-button>
            <span 
                v-if="+applied_filters_count != 0" 
                class="badge badge-danger navbar-badge badge-pill applied-badge"
            >
                {{ applied_filters_count }}
            </span>
        </div>
        <ar-button
            id="apply-more-filter-btn"
            @click="applyFilters()"
            a_class="position-absolute animated fadeIn"
            outlined
            v-if="has_apply && (expanded && !switched && has_switch)">
            Apply
        </ar-button>
        <div id="switch-more-filter-btn" class="position-absolute" v-if="expanded && switched && has_switch">
            <ar-button
                @click="switched = false"
                a_class="animated fadeIn">
                Change
            </ar-button>
            <span 
                v-if="+applied_filters_count != 0" 
                class="badge badge-danger navbar-badge badge-pill applied-badge"
            >
                {{ applied_filters_count }}
            </span>
        </div>

        <div class="animated fadeIn more-filters-con-con" v-show="expanded">
            <div v-show="switched && has_switch" class="animated fadeIn">
                <slot name="switched"></slot>
            </div>
            <div v-show="!switched">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import button from './Button'
    export default {
        name:"ar-more-filters",
        components: {
            'ar-button': button
        },
        props: {
            header: {
                type: Array,
                default: () => ([])
            },
            has_switch: {
                type: Boolean,
                default: false
            },
            has_apply: {
                type: Boolean,
                default: true
            },
            applied_filters_count: {
                default: 0
            }
        },
        data() {
            return {
                expanded: false,
                switched: false
            }
        },
        methods: {
            applyFilters() {
                this.$emit('apply_filters')
                this.switched = true
            }
        }
    }
</script>

<style scoped>
    .more-filters-con {
        box-shadow: 0px -2px 15px -6px rgba(132,132,132,0.67);
        -webkit-transition: height .3s ease;
        transition: height .3s ease;
        position: fixed;
        z-index: 5;
        right: 0;
        bottom: 0;
        height: 58px;
        width: 323px;
        background: #fff;
        border-radius: 4px;
        padding: 49px 0 10px 20px;
    }

    .more-filters-con.expanded {
        height: calc(100vh - 58px);
    }

    .more-filters-con-con {
        padding-right: 20px;
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .more-filters-con-con:hover {
        overflow-y: auto;
    }

    .more-filters-header {
        font-size: 16px;
        padding-left: 45px;
        margin-bottom: 0;
        top: 20px;
        cursor: pointer;
    }

    .more-filters-con.expanded .more-filters-header {
        color : #2D599E;
    }

    .more-filters-header-icon {
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transition: transform .5s ease;
        transition        : transform .5s ease;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .more-filters-header-icon.expanded {
        -webkit-transform: translateY(-50%) rotate(180deg);
        transform        : translateY(-50%) rotate(180deg);
    }

    .more-filters-section-header {
        font-size: 13px;
    }

    #reset-more-filter-btn,
    #switch-more-filter-btn,
    #apply-more-filter-btn {
        top: 13px;
        right: 20px;
    }

    .applied-badge {
        top: -2px;
        right: -2px;
    }
</style>